import { type Organization, type WithContext } from 'schema-dts'
import { type AppTarget, type LanguageItem } from '#app/types/types'

export const APP_TARGET: AppTarget = 'PR'

export const BLOG_URL = 'https://blog.pinayromances.com'

export const COUNTRY_NAME = 'the Philippines'

export const INDEX_HREFLANG_LOCALES = ['en', 'tl']

export const LANGUAGE_NAME = 'Wikang Tagalog'

export const LANGUAGES: LanguageItem[] = [
	{ id: 1, lang: 'en', name: 'English' },
	{ id: 15, lang: 'tl', name: 'Wikang Tagalog' },
	{ id: 18, lang: 'da', name: 'Dansk' },
	{ id: 6, lang: 'de', name: 'Deutsch' },
	{ id: 9, lang: 'es', name: 'Español' },
	{ id: 2, lang: 'fr', name: 'Français' },
	{ id: 8, lang: 'it', name: 'Italiano' },
	{ id: 10, lang: 'pt', name: 'Português' },
	{ id: 3, lang: 'ru', name: 'Pусский' },
	{ id: 22, lang: 'nl', name: 'Nederlands' },
	{ id: 21, lang: 'fi', name: 'Suomi' },
	{ id: 20, lang: 'no', name: 'Norsk' },
	{ id: 17, lang: 'sv', name: 'Svenska' },
	{ id: 11, lang: 'tr', name: 'Türk' },
	{ id: 16, lang: 'id', name: 'Bahasa Indonesia' },
	{ id: 14, lang: 'ms', name: 'Bahasa Melayu' },
	{ id: 5, lang: 'zh', name: '中文' },
	{ id: 4, lang: 'ja', name: '日本人' },
	{ id: 7, lang: 'ko', name: '한국의' },
	{ id: 64, lang: 'th', name: 'ไทย' },
	{ id: 19, lang: 'vi', name: 'Tiếng Việt' },
	{ id: 12, lang: 'ar', name: 'العربية' },
	{ id: 13, lang: 'hi', name: 'हिन्दी' },
]

export const LOCALE = 'tl'

export const NATIONALITY = 'Filipino'

export const SITE_NAME = 'PinayRomances.com'

export const SITE_NAME2 = 'Pinay Romances'

export const SITE_URL = 'https://pinayromances.com'

export const STRIPE_PUBLISHABLE_KEY =
	'pk_live_51JZE0ADBtVdyGEYxwuWDFjIadioz6ETzTfkwC9EcvuOyQDLEcV4uI4waQ2dmwCe02Isp3URZEw4BxOmGDQgFQPB100frgUTThH'

export const TWA_PACKAGE_NAME = 'com.pinayromances.twa'
export const TWA_URL_EN =
	'https://play.google.com/store/apps/details?id=com.pinayromances.twa.en'
export const TWA_URL_LOCALE =
	'https://play.google.com/store/apps/details?id=com.pinayromances.twa.tl'

export const TWITTER_SITE = '@PinayRomances'

export const WEB_PUSH_PUBLIC_KEY =
	'BPvb4OvYAH6CZIXX0JfD5dxtmrAMcKOhbEnm7LuphBwSn18bAVmpmiX2JKw1SEuAyzOYqTXtwOxsKsR7Dh7Qdmo'

export const YANDEX_SITE_VERIFICATION = '4c59ef68bf868809'

// SITE_URL
export const ORGANIZATION_SCHEMA: WithContext<Organization> = {
	'@context': 'https://schema.org',
	'@type': 'Organization',
	name: SITE_NAME2,
	alternateName: SITE_NAME,
	logo: {
		'@type': 'ImageObject',
		url: `${SITE_URL}/static/e/logo.png`,
		width: '180',
		height: '180',
	},
	sameAs: [
		SITE_URL,
		'https://www.facebook.com/pinayromances',
		'https://twitter.com/pinayromances',
		'https://www.youtube.com/channel/UClOwjL8E-b4qAuSenKP_FuA',
	],
	url: SITE_URL,
}

export const SITEMAP_META = {
	description:
		'Site Map by location and age to better find Filipino women and Thai ladyboys',
	image: `${SITE_URL}/static/e/index/beauty-of-philipine-girls-16x9.jpg`,
}
